.grid-container {
  display: block;
  margin-top: 2rem;

  > h1 {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
  }
}
