@import '../theme';

@mixin menu {
  align-items: center;
  display: flex;
  justify-content: center;
}

.language {
  display: flex;
  flex-direction: row-reverse;
  margin: 0.5rem;

  > li {
    cursor: pointer;
    list-style: none;
    padding: 0.5rem;
  }

  &--selected {
    background-color: $theme-selected-language;
    color: $theme-selected-language-text-color;

    &:hover {
      color: $theme-selected-language-text-color;
    }
  }
}

.preface {
  text-align: center;

  > * {
    display: block;
    line-height: 1.5;
    margin-top: 0.5rem;
  }

  > div {
    > div {
      display: inline-grid;
      padding-left: 0.2rem;
      > a {
        color: $text-color;
        text-decoration: none;
      }
    }
    > svg {
      fill: $phone-receiver-color;
      height: 20px;
      position: relative;
      width: 20px;
    }
  }
}

.menu__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  @include menu;
  flex: 1;
  margin-bottom: 1.2rem;

  > img {
    border-top-right-radius: 50%;
    width: 6rem;
  }
}

.content {
  @include menu;
  flex: 3;
  font-size: 1.2rem;
  text-align: center;

  &__menu-items {
    line-height: 2;
    list-style: none;
    padding: 0;

    > :last-child {
      display: none;
    }

    > li > a {
      color: inherit;
      text-decoration: none;
    }
  }
}

.footer {
  @include menu;
  z-index: 5;

  > * {
    padding: 0.8rem;
  }
  > :last-child {
    padding: 0.2rem;
  }
}

.postscript {
  @include menu;
  flex: 1;
  font-size: 0.8rem;
}

li {
  &:hover {
    color: $text-color-hover;
    cursor: pointer;
  }
}

@media screen and (max-width: 850px) {
  .menu {
    display: block;
  }

  .preface {
    font-size: 1.2rem;
  }

  .header {
    min-height: 20vh;
    > img {
      width: 13rem;
    }
  }

  .footer {
    flex-direction: column;
    position: fixed;
    top: 10px;
  }

  .content {
    font-size: 1.5rem;

    &__menu-items {
      > :last-child {
        display: list-item;
      }
    }
  }

  .postscript {
    display: none;
  }
}
