@import '../theme';

.about {
  background-color: $theme-light-gray;
  display: block;
  margin-top: 2rem;

  > h1 {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
  }

  &__section {
    @include centered-items;
    justify-content: space-evenly;
    text-align: center;

    > div {
      @include centered-items;
      flex-direction: column;
      > span {
        font-size: 1rem;
        font-weight: 600;
      }

      > div {
        line-height: 1.5;
        margin: 1rem;
        max-width: 25rem;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .about {
    &__section {
      display: block;
    }
  }
}
