$menu-header-color-1: #acbb78;
$menu-header-color-2: #f7f8f8;
$menu-border-color: rgba(234, 234, 234, 0.5);

$menu-content-color-1: #ffefba;
$menu-content-color-2: #fff;

$footer-color-1: #f7f8f8;
$footer-color-2: #acbb78;

$detail-color-1: #bdc3c7;
$detail-color-2: #2c3e50;

$text-color: #303133;
$text-color-hover: rgba(#303133, 0.5);

$phone-receiver-color: #414a4c;
$theme-selected-language: #999;
$theme-selected-language-text-color: #fff;
$theme-light-gray: #f8faff;
$theme-detail-header-text-color: #fff;

@mixin centered-items {
  align-items: center;
  display: flex;
  justify-content: center;
}

.flip-direction {
  direction: rtl;
}

.default-direction {
  direction: ltr;
}

h4 {
  margin: 0 !important;
}
