@import '../theme';

.contact-container {
  display: none;
  .info {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .contact-container {
    display: block;
    margin-top: 2rem;

    > h1 {
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
    }

    .info {
      @include centered-items;
      flex-direction: column;
      justify-content: space-evenly;

      > div {
        display: inline-grid;
        line-height: 1.5;
        margin-top: 0.5rem;
        padding-left: 0.2rem;
        position: relative;
        > a {
          color: $text-color;
          text-decoration: none;
        }

        > svg {
          fill: $phone-receiver-color;
          height: 20px;
          position: absolute;
          width: 20px;
        }
      }
    }
  }
}
