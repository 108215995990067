@import '../theme';

.services {
  background-color: $theme-light-gray;
  display: block;
  margin-top: 2rem;

  > h1 {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
  }

  &__section {
    @include centered-items;
    justify-content: space-evenly;

    > div {
      @include centered-items;
      flex-direction: column;

      :last-child {
        line-height: 1.5;
        margin: 1rem;
        max-width: 40rem;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .services {
    &__section {
      display: block;

      > div {
        :last-child {
          max-width: 25rem;
        }
      }
    }
  }
}
